import { inject } from "@angular/core";
import { Course } from "@core/domain/course";
import { PlanProfile } from "@core/domain/plan-profile";
import { ProfileModalStateService } from "@feature/profile/services/profile-modal-state.service";
import { TranslateService } from "@ngx-translate/core";
import { CourseDataMapper } from "@shared/services/course-data-mapping.service";
import { CourseData } from "@shared/types/course-data";

export type ConfirmedCourseGroup = {
  title: string;
  subTitle: string | undefined;
  courseData: CourseData[];
};

export class ConfirmCourseListBuilder {
  private courseDataMapper = inject<CourseDataMapper>(CourseDataMapper);

  constructor(
    private translate: TranslateService,
    private modalStateService: ProfileModalStateService,
  ) {}

  buildCourseList(profile: PlanProfile): ConfirmedCourseGroup[] {
    const coursesGroup = this.buildCourseGroup(this.translate.instant("choiceMinorOrElectives.courses"));

    const individualArrangementCourses = this.extractIndividualArrangement(profile);
    const minorCourses = this.extractProfileMinors(coursesGroup);
    this.extractCourses(profile, coursesGroup);

    return [...individualArrangementCourses, ...minorCourses, coursesGroup];
  }

  private extractIndividualArrangement(profile: PlanProfile): ConfirmedCourseGroup[] {
    const groups: ConfirmedCourseGroup[] = [];

    if (profile.individualArrangements?.length) {
      const group = this.buildCourseGroup(this.translate.instant("choiceMinorOrElectives.coursesIndividual"));
      profile.individualArrangements
        .filter((course) => !course.isFlexCourse)
        .forEach((course) => {
          group.courseData.push(this.courseDataMapper.mapCourseData(course));
        });

      group.courseData.length && groups.push(group);
    }

    return groups;
  }

  // Add all selected minor courses to minor group, unless minor points not reached. In that case add courses to "other" group.
  private extractProfileMinors(otherCoursesGroup: ConfirmedCourseGroup): ConfirmedCourseGroup[] {
    const minorGroups: ConfirmedCourseGroup[] = [];
    this.modalStateService.minors
      .filter((profileMinor) => profileMinor.courses.length)
      .forEach((profileMinor) => {
        if (profileMinor.count < profileMinor.minimumPoints) {
          // Minor minimum points not reached, courses will be added as separate courses
          otherCoursesGroup.courseData.push(
            ...profileMinor.courses.map((course) => this.courseDataMapper.mapCourseData(course)),
          );

          // TODO add warning!!!
        } else {
          const minorGroup = this.buildCourseGroup(profileMinor.name);
          minorGroup.courseData.push(
            ...profileMinor.courses.map((course) => this.courseDataMapper.mapCourseData(course)),
          );
          minorGroups.push(minorGroup);
        }
      });

    return minorGroups;
  }

  private extractCourses(profile: PlanProfile, otherCoursesGroup: ConfirmedCourseGroup): void {
    profile.courses.forEach((course) => {
      if (this.modalStateService.isCourseSelected(course)) {
        otherCoursesGroup.courseData.push(this.courseDataMapper.mapCourseData(course));
      }
    });
  }

  private buildCourseGroup(
    header: string,
    subHeader: string | undefined = undefined,
    courses: Course[] = [],
  ): ConfirmedCourseGroup {
    return {
      title: header,
      subTitle: subHeader,
      courseData: courses.map((course) => this.courseDataMapper.mapCourseData(course)),
    };
  }
}
