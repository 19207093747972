import { Component, Input } from "@angular/core";
import { PlanHistoryDetails, PlanHistoryItem } from "@core/domain/plan-history";
import { TranslatePipe } from "@ngx-translate/core";
import { AddCourseListHeaderComponent } from "@shared/components/add-course-list-header/add-course-list-header.component";
import { HistoryCourseItemComponent } from "../history-course-item/history-course-item.component";

@Component({
  selector: "pa-history-courses",
  templateUrl: "./history-courses.component.html",
  styleUrls: ["./history-courses.component.scss"],
  standalone: true,
  imports: [AddCourseListHeaderComponent, HistoryCourseItemComponent, TranslatePipe],
})
export class HistoryCoursesComponent {
  @Input() historyItem!: PlanHistoryItem;
  @Input() historyDetails!: PlanHistoryDetails;

  constructor() {}
}
