import { Component, computed, inject } from "@angular/core";
import { CourseGroup, CourseItem } from "@feature/profile/services/choice-course-list.builder";
import { ProfileModalStateService } from "@feature/profile/services/profile-modal-state.service";
import { AddCourseListHeaderComponent } from "../../add-course-list-header/add-course-list-header.component";
import { AddCourseListItemComponent } from "../../add-course-list-item/add-course-list-item.component";
import { CourseItemComponent } from "../../course-item/course-item.component";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "pa-minor-electives-courses-choice",
  templateUrl: "./minor-electives-courses-choice.component.html",
  styleUrls: ["./minor-electives-courses-choice.component.scss"],
  standalone: true,
  imports: [AddCourseListHeaderComponent, AddCourseListItemComponent, CourseItemComponent, TranslateModule],
})
export class MinorElectivesCoursesChoiceComponent {
  private modalStateService = inject(ProfileModalStateService);

  profile = this.modalStateService.profile;
  pointsReached = this.modalStateService.creditsAllowedReached;
  courseList = computed<CourseGroup[]>(() => {
    this.modalStateService.profile(); // Triggers the effect...
    return this.modalStateService.courseGroups;
  });

  onSelectCourse(courseItem: CourseItem, selected: boolean): void {
    courseItem.selected = selected;
    courseItem.onSelect && courseItem.onSelect();
  }
}
