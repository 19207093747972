<ng-container>
  @if (dataList) {
    <pa-generic-data-table [list]="dataList" />
  }

  @if (historyDetails.attachments.length) {
    <div class="data-table-row data-table-row__whitespace" role="list">
      <span class="data-table-row__label" tabindex="0" role="listitem"
        ><b>{{ 'schedule.history.documenten' | translate }}:</b></span
      >
      <span class="data-table-row__value" role="definition">
        @for (file of historyDetails.attachments; track $index) {
          <pa-download-file
            [documentId]="file.documentId"
            [fileName]="file.fileName"
            [studentNr]="historyDetails.studentNr"
          />
        }
      </span>
    </div>
  }
</ng-container>
