import { Component, Input } from "@angular/core";
import { Constants } from "@core/constants";
import { ProfileModalStep } from "@feature/profile/services/profile-modal-state.service";
import { MinorElectivesChoiceStepComponent } from "@shared/components/minor-electives-state/minor-electives-state-choice/minor-electives-choice-step.component";
import { MinorElectivesConfirmStepComponent } from "@shared/components/minor-electives-state/minor-electives-state-confirm/minor-electives-confirm-step.component";

@Component({
  selector: "pa-minor-electives-detail",
  templateUrl: "./minor-electives-detail.component.html",
  styleUrls: ["./minor-electives-detail.component.scss"],
  standalone: true,
  imports: [MinorElectivesChoiceStepComponent, MinorElectivesConfirmStepComponent],
})
export class MinorElectivesDetailComponent {
  @Input() step!: ProfileModalStep;

  protected readonly Constants = Constants;
  protected readonly ProfileModalStep = ProfileModalStep;
}
