import { NgFor } from "@angular/common";
import { Component, inject, Input } from "@angular/core";
import { CourseTest } from "@app/core/domain/course-test";
import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonTitle,
  IonToolbar,
  ModalController,
} from "@ionic/angular/standalone";
import { TranslatePipe } from "@ngx-translate/core";

@Component({
  selector: "pa-test-info-modal",
  templateUrl: "./test-info-modal.component.html",
  styleUrls: ["./test-info-modal.component.scss"],
  standalone: true,
  imports: [NgFor, TranslatePipe, IonHeader, IonToolbar, IonButton, IonTitle, IonIcon, IonContent],
})
export class TestInfoModalComponent {
  @Input() courseCode!: string;
  @Input() courseName!: string;
  @Input() courseTests!: CourseTest[];
  private modalCtrl = inject(ModalController);

  dismiss(): void {
    this.modalCtrl.dismiss();
  }
}
