<ion-header>
  <ion-toolbar color="light">
    <ion-button fill="clear" slot="start">
      <ion-title>
        <b>{{ courseCode }}</b> {{ courseName }}
      </ion-title>
    </ion-button>
    <ion-button (click)="dismiss()" [attr.aria-label]="'ariaLabel.closeButton' | translate" fill="clear" slot="end">
      <ion-icon icon="close" />
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <p class="ion-padding-horizontal">
    {{ (courseTests.length === 1 ? 'planCourse.testListIntroSingle' : 'planCourse.testListIntroMultiple') | translate }}
  </p>
  <ul>
    @for (coursetest of courseTests; track coursetest) {
      <li>
        <p>{{ coursetest.testDescription }}</p>
      </li>
    }
  </ul>
</ion-content>
