export enum StatusMessageType {
  ERROR = "E",
  WARNING = "W",
}

export type StatusMessage = {
  code: number;
  message: string;
  type?: StatusMessageType;
  year?: number;
  period?: number; // only has a value if year is defined
  courseCode?: string; // only has a value if period is defined
  examTypeDescription?: string;
};
