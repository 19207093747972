import { Component, effect, inject, OnInit } from "@angular/core";
import { Constants } from "@core/constants";
import { PlanProfile } from "@core/domain/plan-profile";
import { PlanService } from "@core/services/plan.service";
import { ProfileModalStateService, ProfileModalStep } from "@feature/profile/services/profile-modal-state.service";
import {
  IonButton,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonTitle,
  IonToolbar,
  ModalController,
} from "@ionic/angular/standalone";
import { TranslatePipe, TranslateService } from "@ngx-translate/core";
import { ChoiceMinorElectivesComponent } from "./choice-minor-electives/choice-minor-electives.component";

@Component({
  selector: "pa-submit-plan-modal",
  templateUrl: "./submit-plan-modal.component.html",
  styleUrls: ["./submit-plan-modal.component.scss"],
  standalone: true,
  imports: [
    ChoiceMinorElectivesComponent,
    TranslatePipe,
    IonHeader,
    IonToolbar,
    IonButton,
    IonTitle,
    IonIcon,
    IonContent,
    IonFooter,
  ],
})
export class SubmitPlanModalComponent implements OnInit {
  readonly Constants = Constants;
  readonly ProfileModalStep = ProfileModalStep;
  profile: PlanProfile | undefined = undefined;
  selectedPoints = 0;
  currentStep: ProfileModalStep = ProfileModalStep.Choice;
  title = "";
  private planService = inject(PlanService);
  private profileModalStateService = inject(ProfileModalStateService);
  private modalCtrl = inject(ModalController);
  private translate = inject(TranslateService);

  constructor() {
    effect(() => {
      this.profile = this.profileModalStateService.profile();
      this.selectedPoints = this.profileModalStateService.selectedPoints();
      this.determineTitle();
    });
  }

  ngOnInit(): void {
    this.determineTitle();
  }

  dismiss(): Promise<boolean> {
    return this.modalCtrl.dismiss(null, Constants.BUTTON_ACTION_CANCEL);
  }

  backPressed(): void {
    this.currentStep = ProfileModalStep.Choice;
    this.determineTitle();
  }

  isSubmitButtonDisabled(): boolean {
    return this.profileModalStateService.submitDisabled;
  }

  submitPressed(): void {
    this.profileModalStateService.submitApproval().subscribe((result) => {
      if (result) {
        this.modalCtrl.dismiss(null, Constants.BUTTON_ACTION_OK);

        // Reload the submitted plan when submit is successful
        if (this.profile) {
          this.planService.getOrFetchPlanDetails(this.profile.id, true).subscribe();
        }
      }
    });
  }

  isNextButtonDisabled(): boolean {
    return this.selectedPoints < this.profile!.minimumPoints;
  }

  nextPressed(): void {
    this.currentStep = ProfileModalStep.Confirm;
    this.determineTitle();
    this.profileModalStateService.nextStep(this.currentStep);
  }

  private determineTitle(): void {
    this.title =
      this.currentStep === ProfileModalStep.Confirm
        ? this.translate.instant("choiceMinorOrElectives.confirmTitle")
        : this.translate.instant("choiceMinorOrElectives.title");
  }
}
