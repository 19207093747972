export enum Role {
  STUDENT = "student",
  EMPLOYEE_DRAFT_PLANS = "employee",
  EMPLOYEE_VIEWS_STUDENT_PLAN = "readonlyEmployee",
}

export type User = {
  studentNr: string;
  name: string;
  roles: Role[];
};
