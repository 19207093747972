import { ApiStatusmelding } from "@core/api/model/api-statusmelding";
import { CaciBoolean } from "@core/api/model/api-types";
import { Constants } from "@core/constants";
import { StatusMessage } from "@core/domain/status-message";

export class ApiHelper {
  constructor() {}

  static caciBooleanToBoolean(value: CaciBoolean): boolean {
    return value === "J";
  }

  static caciBooleanToBooleanOrUndefined(value: CaciBoolean): boolean | undefined {
    if (value === undefined || value === null || value.length === 0) {
      return undefined;
    }

    return value === "J";
  }

  static booleanToCaciBoolean<T>(value: T): CaciBoolean {
    if (value === undefined || value === null) {
      return undefined;
    }

    return value ? "J" : "N";
  }

  static stringListToArray(value: string, separator = Constants.SEPARATOR_COMMA): string[] {
    return value ? value.split(separator).map((str) => str.trim()) : [];
  }

  static numberListToArray(value: string): number[] {
    return value ? value.split(Constants.SEPARATOR_COMMA).map((str) => Number(str.trim())) : [];
  }

  static numberArrayToString(value: number[]): string {
    return value?.map((num) => num.toString()).join(Constants.SEPARATOR_COMMA) ?? "";
  }

  static toStatusMessage(msg: ApiStatusmelding): StatusMessage {
    const statusMessage: StatusMessage = {
      code: msg.code,
      message: msg.tekst,
      type: msg.type,
    };
    if (msg.kolom?.length) {
      if (msg.kolom.indexOf("EXAMENTYPE") === 0) {
        // kolom can contain the exam type description instead of year and period
        statusMessage.examTypeDescription = msg.kolom.split(": ")[1];
      } else {
        // kolom can contain year, period and optionally a course code, separated by commas
        const gridLocation: string[] = this.stringListToArray(msg.kolom);
        if (gridLocation.length > 1 && gridLocation[0].length & gridLocation[1].length) {
          // kolom can also be "SPCU_ID" or something like that, so if length = 1 ignore it
          const parsedYear = Number.parseInt(gridLocation[0]);
          const parsedPeriod = Number.parseInt(gridLocation[1]);
          if (!isNaN(parsedYear) && !isNaN(parsedPeriod)) {
            // the 1st and 2nd entry are numbers
            statusMessage.year = parsedYear;
            statusMessage.period = parsedPeriod;
            // check if there is a 3rd entry, if so it is the course code
            if (gridLocation.length > 2 && gridLocation[2].length) {
              statusMessage.courseCode = gridLocation[2];
            }
          }
        }
      }
    }
    return statusMessage;
  }
}
