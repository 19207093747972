import { Injectable, inject } from "@angular/core";
import { LoadingController } from "@ionic/angular/standalone";
import { TranslateService, Translation } from "@ngx-translate/core";
import { Observable, from, of, switchMap } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LoadingService {
  private loadingCtrl = inject(LoadingController);
  private translateService = inject(TranslateService);

  private loading: HTMLIonLoadingElement | undefined = undefined;

  present(message: string = "label.loading"): Observable<void> {
    const msg: Translation = this.translateService.instant(message);
    if (this.loading) {
      // Loader is already showing, update the message
      this.loading.message = msg;
      return of(void 0);
    } else {
      return from(this.loadingCtrl.create({ message: msg })).pipe(
        switchMap((loading) => {
          this.loading = loading;
          return from(this.loading.present());
        }),
      );
    }
  }

  dismiss(): void {
    if (this.loading) {
      this.loading.dismiss().then(() => {
        this.loading = undefined;
      });
    }
  }
}
