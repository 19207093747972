<ng-container>
  @if (list?.length) {
    @for (item of list; track $index) {
      @if (item.full) {
        <div class="data-table-row" [ngClass]="{ 'data-table-row__whitespace': item.whitespace }" tabindex="0">
          <span class="data-table-row__label-bold" role="listitem">{{ item.label }}</span>
        </div>
        @if (item.value !== undefined && item.value !== null) {
          <div class="data-table-row" role="definition">
            {{ item.value }}
          </div>
        }
      } @else {
        <div class="data-table-row" [ngClass]="{ 'data-table-row__whitespace': item.whitespace }" tabindex="0">
          <span class="data-table-row__label data-table-row__label-bold" role="listitem">{{ item.label }}</span>

          @if (item.value !== undefined && item.value !== null) {
            <span class="data-table-row__value" role="definition">{{ item.value }}</span>
          }
        </div>
      }
    }
  }
</ng-container>
