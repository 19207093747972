import { Component, Input } from "@angular/core";
import { Course } from "@core/domain/course";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "pa-replacement-info",
  templateUrl: "./replacement-info.component.html",
  styleUrls: ["./replacement-info.component.scss"],
  standalone: true,
  imports: [TranslateModule],
})
export class ReplacementInfoComponent {
  @Input() course!: Course;
}
