import { AfterViewChecked, Component, inject, Input, ViewChild } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { Constants } from "@core/constants";
import {
  IonButton,
  IonContent,
  IonFooter,
  IonHeader,
  IonInput,
  IonTitle,
  IonToolbar,
  ModalController,
} from "@ionic/angular/standalone";
import { TranslatePipe } from "@ngx-translate/core";

@Component({
  selector: "pa-rename-copy-draft-modal",
  templateUrl: "./rename-copy-draft-modal.component.html",
  styleUrls: ["./rename-copy-draft-modal.component.scss"],
  standalone: true,
  imports: [FormsModule, TranslatePipe, IonHeader, IonToolbar, IonButton, IonTitle, IonContent, IonInput, IonFooter],
})
export class RenameCopyDraftModalComponent implements AfterViewChecked {
  @Input() title!: string;
  @Input() textOK!: string;
  @Input() textCancel = "label.cancel";
  @ViewChild("draftName") draftName!: IonInput;
  name = "";
  protected readonly Constants = Constants;
  private modalCtrl = inject(ModalController);

  ngAfterViewChecked(): void {
    this.draftName.setFocus();
  }

  confirm(): void {
    this.modalCtrl.dismiss(this.name, Constants.BUTTON_ACTION_OK);
  }

  dismiss(): Promise<boolean> {
    return this.modalCtrl.dismiss(null, Constants.BUTTON_ACTION_CANCEL);
  }
}
