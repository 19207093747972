<ion-header>
  <ion-toolbar color="light">
    <ion-button fill="clear" slot="start">
      <ion-title>
        {{ title }}
      </ion-title>
    </ion-button>
    <ion-button fill="clear" (click)="dismiss()" slot="end" [attr.aria-label]="'ariaLabel.closeButton' | translate">
      <ion-icon icon="close" />
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  @if (history?.items?.length) {
    <pa-history-info [history]="history!" />
  } @else {
    <br />
    <span tabindex="0" class="ion-padding-horizontal">
      {{ 'schedule.history.noHistoryAvailable' | translate }}
    </span>
  }
</ion-content>
