import { Injectable, inject } from "@angular/core";
import { Constants } from "@core/constants";
import { ToastController } from "@ionic/angular/standalone";

@Injectable({
  providedIn: "root",
})
export class ToastService {
  private toastController = inject(ToastController);

  async presentToast(message: string): Promise<void> {
    const toast = await this.toastController.create({
      message: message,
      duration: Constants.TOAST_DURATION,
      position: "bottom",
    });

    await toast.present();
  }
}
