import { HttpParams } from "@angular/common/http";
import { inject, Injectable, OnDestroy } from "@angular/core";
import { ApiUser } from "@core/api/model/api-user";
import { Role, User } from "@core/domain/user";
import { GenericHttpService } from "@core/services/generic-http.service";
import { environment as env } from "@environment/environment";
import { NgxPermissionsService } from "ngx-permissions";
import { BehaviorSubject, map, Observable, of, Subscription, switchMap, tap } from "rxjs";
import { CoreStore } from "../states/core.store";
import { AuthenticationResponse } from "./token.service";

/**
 * This service is responsible for authorizing users, logging out users,
 * as well as managing the user object and the permissions.
 */
@Injectable({
  providedIn: "root",
})
export class AuthService extends GenericHttpService implements OnDestroy {
  private loggedIn = false;
  private userSubject: BehaviorSubject<User | undefined>;
  private userName!: string;
  private permissionsService = inject(NgxPermissionsService);
  private tokenSubscription: Subscription;
  private store = inject(CoreStore);

  constructor() {
    super();
    this.userSubject = new BehaviorSubject<User | undefined>(undefined);
    this.tokenSubscription = this.tokenService.shouldLogout.subscribe((shouldLogout) => {
      if (this.loggedIn && shouldLogout) {
        this.logout();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.tokenSubscription) {
      this.tokenSubscription.unsubscribe();
    }
  }

  getOrFetchUser(): Observable<User> {
    const cachedUser = this.userSubject.getValue();

    if (cachedUser) {
      return of(cachedUser);
    }

    return this.httpGet<ApiUser>("user").pipe(
      // According to the API spec, the user object cannot be undefined here
      map((apiUser) => this.handleUserResponse(apiUser!)),
    );
  }

  getUser$(): Observable<User | undefined> {
    return this.userSubject.asObservable();
  }

  /**
   * Authorizes the logged in user, or if the user is not logged in yet, redirects to the login page.
   * We can have 4 situations here:
   * 1. We get here before logging in.
   *    When a supervisor is doing a deeplink from Osiris Docent to a student plan,
   *    we retrieve the planId from the URL and store it.
   *    Then we check the authentication which will fail,
   *    and will cause a redirect to the login page or single sign-on.
   * 2. Immediately after logging in, when the legacy authorization server is used.
   *    Then we get the access token from the url and we store it.
   * 3. Immediately after logging in, when the Keycloak authorization server is used.
   *    Then we get the authorization code from the url and exchange it for an access token, which we store.
   * 4. Access token is already available in storage.
   *    When a supervisor is doing a deeplink from Osiris Docent to a student plan,
   *    we retrieve the planId from the URL and store it.
   *    Then we check the authentication, to see if this access token is still valid.
   */
  authorize(): Observable<void> {
    let currentUrl = this.window.location.href;
    // example for legacy authorization server (situation 2): http://localhost:4200/#access_token=abc&token_type=bearer&expires_in=0
    // example for Keycloak authorization server (situation 3): http://localhost:4200/?iss=blabla&code=xyz
    currentUrl = currentUrl.replace("/#access_token=", "/?access_token=");
    const urlParams = new URL(currentUrl).searchParams;

    const accessToken = urlParams.get("access_token");
    const authorizationCode = urlParams.get("code");

    if (accessToken) {
      // 2. Legacy authorization server
      this.tokenService.setAccessToken(accessToken);
      this.tokenService.setTokenType(urlParams.get("token_type") || "");
      this.tokenService.removeRefreshToken();
      return this.checkAuthentication().pipe(
        tap(() => {
          this.loggedIn = true;
        }),
      );
    } else if (authorizationCode) {
      // 3. Keycloak authorization server: need to exchange code for access token
      // exchange the Keycloak authorization code for an access token
      const tokenPayload = JSON.stringify({
        code: authorizationCode,
        // We don't need a redirect_uri, because the only deeplink supported is that from Docent,
        // and if that was the case, the plan id was already stored and will be picked up later.
        // Oh and if the language query parameter was set, we also have that in storage.
        redirect_uri: "",
      });
      return this.httpPost<AuthenticationResponse>("token", undefined, tokenPayload).pipe(
        tap((tokenResponse: AuthenticationResponse) => {
          this.tokenService.setAccessToken(tokenResponse.access_token);
          this.tokenService.setTokenType(tokenResponse.token_type);
          this.tokenService.setRefreshToken(tokenResponse.refresh_token);
        }),
        switchMap(() => {
          return this.checkAuthentication();
        }),
        tap(() => {
          this.loggedIn = true;
        }),
        // Note that Keycloak requires that the access token is refreshed every couple of minutes.
        // This is handled by the AuthInterceptor.
      );
    } else {
      // 1. and 4. Not logged in yet or access token is already available
      this.extractPlanIdIfDeeplinkDocent();
      return this.checkAuthentication().pipe(
        tap(() => {
          this.loggedIn = true;
        }),
      );
    }
  }

  private checkAuthentication(): Observable<void> {
    const authenticateParams = new HttpParams().set("authentication_type", env.authentication_type ?? "student");
    return this.httpGet<void>("authenticeer", authenticateParams);
    // Note that if the user is not authorized, the 401 response is handled by the AuthInterceptor.
  }

  private extractPlanIdIfDeeplinkDocent(): void {
    // Extact planId from /bekijkplanning/id=xxx in the URL and store it.
    const urlPath = this.window.location.pathname || "/";
    const searchStr = "/bekijkplanning/id=";
    const searchPos = urlPath.indexOf(searchStr);
    const isNavigateFromDocent = searchPos !== -1;
    const planId = isNavigateFromDocent ? urlPath.substring(searchPos + searchStr.length) : null;
    if (isNavigateFromDocent) {
      this.saveStudentPlanId(planId);
    }
  }

  private removeStudentPlanId(): void {
    this.store.removeStudentPlanIdForEmployee();
  }

  private saveStudentPlanId(planId: string | null): void {
    if (!planId) {
      this.removeStudentPlanId();
    } else {
      const planDigits = planId.replace(/(^\d+)(.*$)/i, "$1");
      this.store.setStudentPlanIdForEmployee(planDigits);
    }
  }

  logout(): void {
    this.removeStudentPlanId();
    this.httpGet<string>("uitloggen").subscribe((res) => {
      const redirectUrl = this.extractRedirectUrlFromResponse(res);
      this.loggedIn = false;
      this.userName = "";
      this.userSubject.next(undefined);

      this.tokenService.removeAllTokenData();

      // Redirecting to a page outside this application.
      this.window.location.href = redirectUrl ?? "/session/login";
    });
  }

  isLoggedIn(): boolean {
    return this.loggedIn;
  }

  isRoleEmployeeDraftPlans(): boolean {
    return Role.EMPLOYEE_DRAFT_PLANS === this.permissionsService.getPermission(Role.EMPLOYEE_DRAFT_PLANS)?.name;
  }

  isRoleStudent(): boolean {
    return Role.STUDENT === this.permissionsService.getPermission(Role.STUDENT)?.name;
  }

  isRoleEmployeeViewsStudentPlan(): boolean {
    return (
      Role.EMPLOYEE_VIEWS_STUDENT_PLAN === this.permissionsService.getPermission(Role.EMPLOYEE_VIEWS_STUDENT_PLAN)?.name
    );
  }

  getUser(): string {
    return this.userName;
  }

  private handleUserResponse(apiUser: ApiUser): User {
    const nameParts = [
      apiUser.roepnaam?.trim() || apiUser.voorletters?.trim(), // Use roepnaam if available, otherwise use voorletters
      apiUser.voorvoegsels?.trim(),
      apiUser.achternaam.trim(),
    ]
      .filter((part) => part)
      .join(" ");

    const user = {
      studentNr: apiUser.studentnummer,
      name: nameParts || "",
      roles: apiUser.roles,
    } as User;

    this.userName = user.name;
    this.userSubject.next(user);

    switch (true) {
      case user.roles.includes(Role.STUDENT):
        this.permissionsService.loadPermissions([Role.STUDENT]);
        break;
      case user.roles.includes(Role.EMPLOYEE_DRAFT_PLANS):
        this.permissionsService.loadPermissions([Role.EMPLOYEE_DRAFT_PLANS]);
        break;
      default:
        console.error("Not supported user role", user.roles);
    }

    if (user.roles.includes(Role.STUDENT)) {
      // /bekijkplanning/id= is not for students (employee logs in to view plan of student)
      this.removeStudentPlanId();
    }

    return user;
  }
}
