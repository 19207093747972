import { Component, Input, OnInit } from "@angular/core";
import { StatusMessage, StatusMessageType } from "@core/domain/status-message";
import { IonIcon } from "@ionic/angular/standalone";

@Component({
  selector: "pa-status-message",
  templateUrl: "./status-message.component.html",
  styleUrls: ["./status-message.component.scss"],
  standalone: true,
  imports: [IonIcon],
})
export class StatusMessageComponent implements OnInit {
  @Input() melding: StatusMessage | undefined;
  icon?: string;

  ngOnInit(): void {
    this.icon = this.melding?.type === StatusMessageType.ERROR ? "remove-circle" : "alert-circle-outline";
  }
}
