import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from "@angular/core";
import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonModal,
  IonSearchbar,
  IonTitle,
  IonToolbar,
  SearchbarCustomEvent,
} from "@ionic/angular/standalone";
import { TranslatePipe } from "@ngx-translate/core";
import { searchableData } from "@shared/modals/searchable-select/searchable-data";

@Component({
  selector: "pa-searchable-select",
  templateUrl: "./searchable-select.component.html",
  styleUrls: ["./searchable-select.component.scss"],
  standalone: true,
  imports: [
    TranslatePipe,
    IonModal,
    IonHeader,
    IonToolbar,
    IonButton,
    IonTitle,
    IonIcon,
    IonContent,
    IonSearchbar,
    IonItem,
    IonLabel,
  ],
})
export class SearchableSelectComponent implements OnChanges {
  @Input() title!: string;
  @Input() data!: searchableData[];
  @Input() itemTextField!: string;
  @Input() textCancel = "label.cancel";
  @Output() selectedChanged: EventEmitter<searchableData> = new EventEmitter();

  @ViewChild("searchbar") searchbar!: IonSearchbar;

  isOpen = false;
  selected!: searchableData | undefined;
  filtered: searchableData[] = [];

  onDidPresent(): void {
    this.searchbar.setFocus();
  }

  onDidDismiss(): void {
    this.isOpen = false;
  }

  ngOnChanges(): void {
    this.setFiltered(this.data);
    this.selected = undefined;
  }

  open(): void {
    if (this.data?.length) {
      this.isOpen = true;
      this.setFiltered(this.data);
    }
  }

  cancel(): void {
    this.isOpen = false;
  }

  itemSelected(item: searchableData): void {
    //reset list of filtered items
    this.setFiltered([]);

    this.selected = item;
    this.selectedChanged.emit(item);
    this.cancel();
  }

  filterItems(event: SearchbarCustomEvent): void {
    const filter = event.detail.value?.toLowerCase() as string;
    this.filtered = this.data.filter((item) => {
      const filterField = item.value;
      return filterField.toLowerCase().indexOf(filter) >= 0;
    });
  }

  private setFiltered(data: searchableData[]): void {
    this.filtered = data;
  }
}
