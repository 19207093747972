import { Component, inject, Input, OnInit } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { Role } from "@app/core/domain/user";
import { Constants } from "@core/constants";
import { Draft } from "@core/domain/draft";
import { Education } from "@core/domain/education";
import { ExamType } from "@core/domain/examtype";
import { StudyProgram } from "@core/domain/study-program";
import { EducationService } from "@feature/education/services/education.service";
import { ExamTypeService } from "@feature/examtype/services/exam-type.service";
import { StudyProgramService } from "@feature/studyprogram/services/studyprogam.service";
import {
  IonButton,
  IonContent,
  IonFooter,
  IonHeader,
  IonInput,
  IonItem,
  IonList,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTitle,
  IonToolbar,
  ModalController,
} from "@ionic/angular/standalone";
import { TranslatePipe } from "@ngx-translate/core";
import { searchableData } from "@shared/modals/searchable-select/searchable-data";
import { NgxPermissionsModule } from "ngx-permissions";
import { SearchableSelectComponent } from "../searchable-select/searchable-select.component";

type StartingYear = {
  value: string;
  label: string;
};

@Component({
  selector: "pa-new-draft-modal",
  templateUrl: "./new-draft-modal.component.html",
  styleUrls: ["./new-draft-modal.component.scss"],
  standalone: true,
  imports: [
    FormsModule,
    SearchableSelectComponent,
    NgxPermissionsModule,
    TranslatePipe,
    IonHeader,
    IonToolbar,
    IonButton,
    IonTitle,
    IonContent,
    IonList,
    IonInput,
    IonItem,
    IonSelect,
    IonSelectOption,
    IonText,
    IonFooter,
  ],
})
export class NewDraftModalComponent implements OnInit {
  @Input() title!: string;
  @Input() textOK = "menuGeneral.btnAdd";
  @Input() textCancel = "label.cancel";
  draft = {} as Draft;
  startYears = this.getStartYears();
  examTypes!: ExamType[];
  educations!: Education[];
  educationsOriginal!: Education[];
  studyPrograms: StudyProgram[] = [];
  secondStudyPrograms: StudyProgram[] = [];
  maxLengthName = 35;
  searchableEducations: searchableData[] = [];
  disableStudyProgram = true;
  draftEducationName? = "";
  protected readonly Role = Role;
  protected readonly Constants = Constants;
  private modalCtrl = inject(ModalController);
  private educationService = inject(EducationService);
  private examTypeService = inject(ExamTypeService);
  private studyProgramService = inject(StudyProgramService);

  ngOnInit(): void {
    this.fetchExamTypes();
    this.fetchEducations();
  }

  confirm(): void {
    this.modalCtrl.dismiss(this.draft, Constants.BUTTON_ACTION_OK);
  }

  getStartYears(): StartingYear[] {
    const nextYear = new Date().getFullYear() + 1;
    const list: StartingYear[] = [];

    for (let i = nextYear; i > nextYear - 6; i--) {
      list.push({ label: `${i} - ${i + 1}`, value: i.toString() });
    }
    return list;
  }

  fetchEducations(): void {
    this.educationsOriginal = [];

    this.educationService.getEducations().subscribe((educations) => {
      this.educationsOriginal = educations;
    });
  }

  getEducations(): void {
    this.educations = this.educationsOriginal.filter((education) => education.examType === this.draft.examType);

    this.searchableEducations = this.educations.map<searchableData>((education) => {
      return { key: education.education, value: education.name };
    });

    this.startYearOrMajorChanged();
  }

  fetchExamTypes(): void {
    this.examTypeService.getExamTypes().subscribe((examTypes) => (this.examTypes = examTypes));
  }

  startYearOrMajorChanged(): void {
    this.draft.studyProgramCode = "";
    this.draft.secondStudyProgramCode = undefined;

    if (this.draft.education && this.draft.startingYear && this.draft.examType) {
      this.studyProgramService.getStudyPrograms(this.draft).subscribe((studyPrograms) => {
        this.studyPrograms = studyPrograms;
      });
    }
  }

  dismiss(): Promise<boolean> {
    return this.modalCtrl.dismiss(null, Constants.BUTTON_ACTION_CANCEL);
  }

  studyProgramChanged(): void {
    const selectedStudyProgram = this.studyPrograms.find(
      (studyProgram) => this.draft.studyProgramCode === studyProgram.code,
    );
    this.draft.secondStudyProgramCode = undefined;
    this.secondStudyPrograms = [];

    if (selectedStudyProgram?.showSecondStudyProgram) {
      const dummyDraft: Draft = {
        education: this.draft.education,
        startingYear: this.draft.startingYear,
        examType: "",
      };
      this.studyProgramService.getStudyPrograms(dummyDraft, true).subscribe((studyPrograms) => {
        this.secondStudyPrograms = studyPrograms;
      });
    }
  }

  showStudentWarning(studyPrograms: StudyProgram[], studyProgramCode?: string): boolean {
    if (studyPrograms && studyProgramCode) {
      return studyPrograms.some(
        (studyProgram) => studyProgramCode === studyProgram.code && !studyProgram.isRegisteredByThisStudent,
      );
    }

    return false;
  }

  isComplete(): boolean {
    return (
      //
      (!this.isEmpty(this.draft.name) &&
        this.draft.name &&
        this.draft.name.length <= this.maxLengthName &&
        !this.isEmpty(this.draft.studyProgramCode) &&
        !this.isEmpty(this.draft.examType) &&
        !this.isEmpty(this.draft.startingYear) &&
        !this.isEmpty(this.draft.education) &&
        (!this.isEmpty(this.draft.secondStudyProgramCode) ||
          (this.draft.secondStudyProgramCode === undefined && this.secondStudyPrograms.length === 0))) as boolean
    );
  }

  isEmpty(value: string | number | undefined | null): boolean {
    return value === null || value === undefined || (typeof value === "string" && value.trim() === "");
  }

  selectChanged(event: searchableData): void {
    this.draft.education = event.key.toString();
    this.disableStudyProgram = false;
    this.draftEducationName = this.searchableEducations.find((draft) => draft.key === this.draft.education)?.value;
    this.startYearOrMajorChanged();
  }
}
