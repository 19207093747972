import { Component, inject, Input, OnInit } from "@angular/core";
import { AcademicYear } from "@core/domain/academic-year";
import { PlanYearService } from "@feature/schedule/services/plan-year.service";
import {
  InputCustomEvent,
  IonButton,
  IonContent,
  IonFooter,
  IonHeader,
  IonItem,
  IonList,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
  ModalController,
} from "@ionic/angular/standalone";
import { TranslatePipe } from "@ngx-translate/core";

@Component({
  selector: "pa-change-year-modal",
  templateUrl: "./change-year-modal.component.html",
  styleUrls: ["./change-year-modal.component.scss"],
  standalone: true,
  imports: [
    TranslatePipe,
    IonHeader,
    IonToolbar,
    IonButton,
    IonTitle,
    IonContent,
    IonList,
    IonItem,
    IonSelect,
    IonSelectOption,
    IonFooter,
  ],
})
export class ChangeYearModalComponent implements OnInit {
  @Input() title!: string;
  @Input() textOK = "menuGeneral.btnChangeStartYearSubmit";
  @Input() textCancel = "label.cancel";
  selectedYear = 0;
  academicYears!: AcademicYear[];
  planYearService = inject(PlanYearService);
  private modalCtrl = inject(ModalController);

  ngOnInit(): void {
    this.planYearService.fetchAcademicYears().subscribe((years) => {
      this.academicYears = years ?? [];
    });
  }

  cancel(): void {
    this.modalCtrl.dismiss(null, "cancel");
  }

  confirm(): void {
    this.modalCtrl.dismiss(this.selectedYear, "ok");
  }

  handleChange($event: Event): void {
    const changeEvent = $event as InputCustomEvent;

    if (changeEvent.detail.value) {
      this.selectedYear = Number.parseInt(changeEvent.detail.value);
    }
  }
}
