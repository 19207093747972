import { OnInit, Component, Input, inject } from "@angular/core";
import { PlanProfile } from "@core/domain/plan-profile";
import { ProfileModalStateService, ProfileModalStep } from "@feature/profile/services/profile-modal-state.service";
import { MinorElectivesDetailComponent } from "../minor-electives-detail/minor-electives-detail.component";
import { MinorElectivesCoursesComponent } from "../minor-electives-courses/minor-electives-courses.component";

@Component({
  selector: "pa-choice-minor-electives",
  templateUrl: "./choice-minor-electives.component.html",
  styleUrls: ["./choice-minor-electives.component.scss"],
  standalone: true,
  imports: [MinorElectivesDetailComponent, MinorElectivesCoursesComponent],
})
export class ChoiceMinorElectivesComponent implements OnInit {
  private modalStateService = inject(ProfileModalStateService);

  @Input() profile!: PlanProfile;
  @Input() step!: ProfileModalStep;

  public approveRemark = "";
  public submitBtnDisabled: boolean = false;

  ngOnInit(): void {
    if (this.profile) {
      this.modalStateService.initProfile(this.profile, this.step);
    }
  }
}
