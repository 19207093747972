import { Course } from "@core/domain/course";
import { MinorProfile, PlanProfile } from "@core/domain/plan-profile";
import { ProfileModalStateService } from "@feature/profile/services/profile-modal-state.service";
import { TranslateService } from "@ngx-translate/core";
import { CourseDataMapper } from "@shared/services/course-data-mapping.service";
import { CourseData } from "@shared/types/course-data";

export type CourseItem = {
  selected: boolean;
  selectable: boolean;
  disabled: boolean;
  course: Course;
  courseData: CourseData;
  onSelect?: () => void;
};

export type CourseGroup = {
  title: string;
  subTitle: string | undefined;
  items: CourseItem[];
};

export class ChoiceCourseListBuilder {
  constructor(
    private translate: TranslateService,
    private courseDataMapper: CourseDataMapper,
    private modalStateService: ProfileModalStateService,
  ) {}

  buildCourseList(profile: PlanProfile): CourseGroup[] {
    const individualArrangement = this.extractIndividualArrangement(profile);
    const profileMinors = this.extractProfileMinors(profile);
    const courses = this.extractCourses(profile);

    return [...individualArrangement, ...profileMinors, ...courses];
  }

  private extractIndividualArrangement(profile: PlanProfile): CourseGroup[] {
    const groups: CourseGroup[] = [];

    if (profile?.individualArrangements?.length) {
      const group = this.buildCourseGroup(this.translate.instant("choiceMinorOrElectives.coursesIndividual"));
      profile.individualArrangements
        .filter((course) => !course.isFlexCourse)
        .forEach((course) => {
          this.addCourseToGroup(course, group, true, false, true);
        });

      group.items.length && groups.push(group);
    }

    return groups;
  }

  private extractProfileMinors(profile: PlanProfile): CourseGroup[] {
    const groups: CourseGroup[] = [];

    if (profile.minors?.length) {
      profile.minors
        // .filter(course => !course.isFlexCourse) // TODO How to handle flex courses?
        .forEach((minor) => {
          const profileMinor = this.modalStateService.minors.find((pm) => pm.minor === minor.minor);
          if (profileMinor) {
            // always true
            const warning =
              profileMinor.count < profileMinor.minimumPoints
                ? `(${this.translate.instant("choiceMinorOrElectives.warningNotEnoughCredits")})`
                : undefined;
            const group = this.buildCourseGroup(minor.name, warning);

            this.findAllMinorCourses(minor).forEach((course) => {
              if (this.isInIndividualArrangements(profile, course)) {
                // TODO What is the desired behaviour? According to old code, the course should not be visible but
                //      should count for the minor minimum requirements. For now: do not include course in minor.
              } else {
                this.addCourseToGroup(course, group, false, true, false, () => {
                  this.modalStateService.courseSelected(profile, course, minor.minor);
                });
              }
            });

            group.items.length && groups.push(group);
          }
        });
    }

    return groups;
  }

  private findAllMinorCourses(minor: MinorProfile): Course[] {
    return minor.examComponents.flatMap((comp) => comp.courses);
  }

  private isInIndividualArrangements(profile: PlanProfile, course: Course): boolean {
    return profile.individualArrangements.some((c) => c.id === course.id);
  }

  private extractCourses(profile: PlanProfile): CourseGroup[] {
    const groups: CourseGroup[] = [];

    if (profile?.courses?.length) {
      const group = this.buildCourseGroup(this.translate.instant("choiceMinorOrElectives.courses"));
      profile.courses
        .filter((course) => !course.isFlexCourse) // TODO: What if course is flex course?
        // .filter(course => !this.planStateService.isPlannedCourse(course))
        .forEach((course) => {
          this.addCourseToGroup(course, group, false, true, false, () => {
            this.modalStateService.courseSelected(profile, course);
          });
        });

      group.items.length && groups.push(group);
    }

    return groups;
  }

  // private extractOtherCourses(courses: Course[]): CourseGroup | undefined {
  //   if (courses?.length) {
  //     const group = this.buildCourseGroup(this.translate.instant('selectCourse.otherCourses'));
  //     courses
  //       .filter(course => !course.isFlexCourse)
  //       .filter(course => !this.planStateService.isPlannedCourse(course))
  //       .forEach(course => {
  //         this.addCourseToGroup(course, group);
  //       });
  //     return group.items.length ? group : undefined;
  //   }
  //
  //   return undefined;
  // }

  private buildCourseGroup(
    header: string,
    subHeader: string | undefined = undefined,
    items: CourseItem[] = [],
  ): CourseGroup {
    return {
      title: header,
      subTitle: subHeader,
      items,
    };
  }

  private addCourseToGroup(
    course: Course,
    group: CourseGroup,
    selected = false,
    selectable = true,
    disabled = false,
    onSelect?: () => void,
  ): void {
    const courseData = this.courseDataMapper.mapCourseData(course);
    group.items.push({
      selected,
      selectable,
      disabled,
      course,
      courseData,
      onSelect,
    } as CourseItem);
  }
}
