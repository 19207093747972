import { Component, ElementRef, inject, Input, ViewChild } from "@angular/core";
import { PlanHistoryService } from "@core/services/plan-history.service";
import { IonIcon } from "@ionic/angular/standalone";

@Component({
  selector: "pa-download-file",
  templateUrl: "./download-file.component.html",
  styleUrls: ["./download-file.component.scss"],
  standalone: true,
  imports: [IonIcon],
})
export class DownloadFileComponent {
  @Input() fileName!: string;
  @Input() documentId!: number;
  @Input() studentNr!: string;
  @ViewChild("downloadLink") downloadLink!: ElementRef<HTMLAnchorElement>;
  private planHistoryService = inject(PlanHistoryService);

  openDocument(event: MouseEvent): void {
    event.preventDefault();

    this.planHistoryService
      .getDocument(this.documentId, this.studentNr)
      //
      .subscribe((blob) => {
        this.downloadLink.nativeElement.href = window.URL.createObjectURL(blob);
        this.downloadLink.nativeElement.download = this.fileName;
        this.downloadLink.nativeElement.click();

        // Clear the temp download url
        this.downloadLink.nativeElement.href = "";
      });
  }
}
