import { Component, Input } from "@angular/core";
import { ProfileModalStep } from "@feature/profile/services/profile-modal-state.service";
import { MinorElectivesCoursesChoiceComponent } from "@shared/components/minor-electives-courses/minor-electives-courses-choice/minor-electives-courses-choice.component";
import { MinorElectivesCoursesConfirmComponent } from "@shared/components/minor-electives-courses/minor-electives-courses-confirm/minor-electives-courses-confirm.component";

@Component({
  selector: "pa-minor-electives-courses",
  templateUrl: "./minor-electives-courses.component.html",
  styleUrls: ["./minor-electives-courses.component.scss"],
  standalone: true,
  imports: [MinorElectivesCoursesChoiceComponent, MinorElectivesCoursesConfirmComponent],
})
export class MinorElectivesCoursesComponent {
  @Input() step!: ProfileModalStep;
  protected readonly ProfileModalStep = ProfileModalStep;
}
