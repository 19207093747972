import { Injectable, SecurityContext, inject } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Injectable({
  providedIn: "root",
})
export class CaciSanitizer {
  private sanitizer = inject(DomSanitizer);

  sanitize(sanitizeMe: string | null): string | null {
    return this.sanitizer.sanitize(SecurityContext.HTML, this.extractHtmlBody(sanitizeMe));
  }

  private extractHtmlBody(sanitizeMe: string | null): string | null {
    // if sanitizeMe consists of html head and body tags, only the content of the body tag is returned
    // we do this to prevent the DomSanitizer from causing a console error like this:
    //   Refused to apply inline style because it violates the following Content Security Policy directive: "style-src 'nonce-4b4062191c470a17f8a5b9007e8be60bc108240a346ee08befdf400fd2aba739' 'strict-dynamic'".
    //   Either the 'unsafe-inline' keyword, a hash ('sha256-prEYzsvGck9MCzEF8WJ7CSN79jBFmKGLeuF0NUvUu/A='), or a nonce ('nonce-...') is required to enable inline execution.
    if (sanitizeMe) {
      const bodyStart = sanitizeMe.indexOf("<body>");
      const bodyEnd = sanitizeMe.indexOf("</body>");
      if (bodyStart !== -1 && bodyEnd !== -1) {
        return sanitizeMe.substring(bodyStart + 6, bodyEnd);
      }
    }
    return sanitizeMe;
  }
}
