import { inject, Injectable } from "@angular/core";
import { Course } from "@core/domain/course";
import { CourseAcademicYear, CoursePeriod } from "@core/domain/course-detail";
import { PlanHelper } from "@core/domain/helpers/plan-helper";
import { Period, PlanDetails, StudyYear } from "@core/domain/plan-details";
import { PlanHistoryDetailCourse } from "@core/domain/plan-history";
import { AuthService } from "@core/services/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { CourseTestBlock } from "../course-test";
import { ProposalReviewMoment, ProposalStatus } from "../proposal-status";

@Injectable({
  providedIn: "root",
})
export class CourseHelper {
  private readonly colorRegex = /#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})/;

  private authService = inject(AuthService);
  private planHelper = inject(PlanHelper);
  private translate = inject(TranslateService);

  getStatusObtainedLabel(course: Course): string {
    return course.statusObtained !== undefined
      ? course.statusObtained
        ? "planCourse.statusObtained"
        : "planCourse.statusNotObtained"
      : "";
  }

  courseRegisteredInPlanPeriod(course: Course): boolean {
    return course.yearNr === course.statusStudyYear && course.periodNr === course.statusPeriod;
  }

  isCourseObtained(course: Course): boolean {
    return course && course.statusObtained === true;
  }

  public isCustomCourse(course: Course | PlanHistoryDetailCourse): boolean {
    return !!course.customCourseName?.length;
  }

  getCourseId(course: Course): string {
    const courseIdOrCode = course.id ?? course.code;
    // Return the course id, optionally with ribbon number if it is a ribbon course
    return course.ribbonNr === 1 ? `${courseIdOrCode}` : `${courseIdOrCode}-${course.ribbonNr}`;
  }

  getCourseName(course: Course | PlanHistoryDetailCourse): string {
    return this.isCustomCourse(course) ? course.customCourseName! : course.name!;
  }

  hasRibbonPeriods(course: Course): boolean {
    return !!course.ribbonPeriods?.length;
  }

  isRibbonCourse(course: Course): boolean {
    // This function is used to see if the link icon may be displayed
    return this.hasRibbonPeriods(course) && course.yearNr > 0;
  }

  isFirstPartOfRibbon(course: Course): boolean {
    return !!course.ribbonPeriods?.length && course.ribbonNr === 1;
  }

  isMoveAllowed(course: Course): boolean {
    return (
      course.statusObtained !== true &&
      !course.isRibbon &&
      !this.isCustomCourse(course) &&
      (this.authService.isRoleStudent() || this.authService.isRoleEmployeeDraftPlans())
    );
  }

  private courseMustStayInPlan(course: Course, plan?: PlanDetails): boolean {
    return (
      course.isRegistered ||
      course.statusObtained ||
      course.proposalStatus === ProposalStatus.SUBMITTED_FOR_APPROVAL ||
      course.proposalStatus === ProposalStatus.POSITIVE_ADVICE ||
      (course.proposalStatus === ProposalStatus.APPROVED && plan?.proposalReviewMoment === ProposalReviewMoment.FINAL)
    );
  }

  isRemoveAllowed(course: Course, plan?: PlanDetails): boolean {
    return (
      course.ribbonNr === 1 &&
      (this.authService.isRoleStudent() || this.authService.isRoleEmployeeDraftPlans()) &&
      !this.courseMustStayInPlan(course, plan)
    );
  }

  hasActions(course: Course): boolean {
    return (
      !course.isCourseAtAnotherInstitution &&
      !(this.authService.isRoleEmployeeViewsStudentPlan() && this.isCustomCourse(course))
    );
  }

  /**
   * Returns the color of the course if it is a valid color, otherwise undefined.
   */
  getCourseColor(course: Course | PlanHistoryDetailCourse): string | undefined {
    return course.color && this.colorRegex.test(course.color) ? course.color : undefined;
  }

  createEmptyCourse(): Course {
    return {
      studyPoints: 0,
      customCourseName: "",
      customCoursePoints: 0,
      timeslots: [],
      ribbonNr: 1,
      isInPlanLater: false,
      isFlexCourse: false,
      isCourseAtAnotherInstitution: false,
    } as unknown as Course;
  }

  isSameCourse(course1: Course, course2: Course): boolean {
    // if one of the courses is undefined, they are not the same
    if (!course1 || !course2) {
      return false;
    }

    if (!!course1.code && !!course2.code) {
      // compare by code if both have a code
      return course1.code === course2.code;
    }

    if (!!course1.id && !!course2.id) {
      // compare by id if both have an id
      return course1.id === course2.id;
    }

    // else compare by name
    return this.getCourseName(course1) === this.getCourseName(course2);
  }

  // Creates one or more copies of the course (if it's marked as a ribbon course). The copies are added
  // to the plan in the corresponding periods marked in the ribbon field.
  planRibbonCourses(plan: PlanDetails, course: Course): void {
    const ribbonCourses: Course[] = this.getPlanRibbonCourses(plan, course);
    ribbonCourses.forEach((ribbonCourse) => {
      const year = this.planHelper.getPlanYear(plan, ribbonCourse.yearNr);
      const period = this.planHelper.getPlanPeriod(year.periods, ribbonCourse.periodNr);
      period.courses.push(ribbonCourse);
      // Re-sort courses: ribbon courses first, then by code
      period.courses = this.sortCourses(period.courses);
      // re-apply course tests
      this.planHelper.refreshCourseTests(period);
    });
  }

  getTestCount(course: Course | undefined, courseTestBlock: CourseTestBlock | undefined): number {
    if (course?.courseTests) {
      return course.courseTests.length;
    }
    if (courseTestBlock?.courseTests) {
      return courseTestBlock.courseTests.length;
    }
    return 0;
  }

  getCoursePoints(course: Course): number {
    return (this.isCustomCourse(course) ? course.customCoursePoints : course.studyPoints) ?? 0;
  }

  isRemovableFromPlanLater(course: Course): boolean {
    return (this.authService.isRoleStudent() || this.authService.isRoleEmployeeDraftPlans()) && !course.isRegistered;
  }

  private getPlanRibbonCourses(plan: PlanDetails, course: Course) {
    const ribbonCourses: Course[] = [];

    const countAcademicYears: number = plan.studyYears.length;
    const countPeriods: number = plan.studyYears[0].periods.length;
    const orgPeriodNr: number = (course.yearNr - 1) * countPeriods + course.periodNr; // grid number of the 'original' course
    const totalCountPeriods: number = countAcademicYears * countPeriods; // max number of the grid

    for (let i = 1; i < course.ribbonPeriods.length; i++) {
      const ribbonCourse = { ...course };
      const ribbonNr = orgPeriodNr + course.ribbonPeriods[i] - course.ribbonPeriods[0]; // lint periode nr
      const academicYear: number = this.getRibbonAcademicYear(ribbonNr, countPeriods); // get college year
      const periodNr: number = this.getRibbonPeriodNumber(ribbonNr, countPeriods); // get period number

      // if max count periods is bigger as lint number plan the lint course.
      if (totalCountPeriods >= ribbonNr) {
        ribbonCourse.isRibbon = true;
        ribbonCourse.yearNr = academicYear;
        ribbonCourse.periodNr = periodNr;
        ribbonCourse.ribbonNr = course.ribbonNr + i;
        ribbonCourse.statusMessages = undefined;
        ribbonCourse.studyPoints = 0;
        ribbonCourses.push(ribbonCourse);
      }
    }

    return ribbonCourses;
  }

  private getRibbonAcademicYear(ribbonNr: number, periods: number): number {
    return Math.ceil(ribbonNr / periods);
  }

  private getRibbonPeriodNumber(ribbonNr: number, periods: number): number {
    return ((ribbonNr - 1) % periods) + 1;
  }

  public isCoursePlannable(course: Course): boolean {
    return !!(course && course.yearNr! === 0);
  }

  public findPlannedCourse(course: Course, selectedPlan?: PlanDetails): Course | undefined {
    return selectedPlan?.studyYears
      .flatMap((year) => year.periods)
      .flatMap((period) => period.courses)
      .find((c) => c.code === course.code && c.yearNr !== undefined);
  }

  public isPlannedCourse(course: Course, selectedPlan?: PlanDetails): boolean {
    return this.findPlannedCourse(course, selectedPlan) !== undefined;
  }

  public getPlannedText(course: Course): string {
    if (course.yearNr > 0 && course.periodDescription !== "") {
      const plannedText = `${this.translate.instant("schedule.labelAcademicYear")} ${course.yearNr}`;

      if (this.hasRibbonPeriods(course)) {
        return plannedText;
      }

      return plannedText.concat(` - ${course.periodDescription} ${course.periodNr}`);
    }

    return "";
  }

  public updateCoursePeriodInfo(course: Course, selectedPlan: PlanDetails | undefined): Course {
    if (!selectedPlan) {
      return course;
    }

    const planCourse = selectedPlan.studyYears
      .flatMap((year) => year.periods)
      .flatMap((period) => period.courses)
      .find((c) => this.isSameCourse(c, course));

    if (planCourse) {
      course.yearNr = planCourse.yearNr;
      course.periodNr = planCourse.periodNr;
      course.periodDescription = planCourse.periodDescription;
    }

    return course;
  }

  // Strip any number and whitespaces after the period description e.g. blok 1 -> blok
  public stripPeriodDescriptionNumber(periodDescription: string): string {
    return periodDescription.replace(/\s+\d+/g, "");
  }

  public toCourseAcademicYear(studyYear: StudyYear): CourseAcademicYear {
    return {
      academicYear: studyYear.academicYear,
      flexiblePlanCourse: false,
      yearNr: studyYear.yearNr,
      academicYearDescription: studyYear.academicYearDescription,
      periods: studyYear.periods?.map((period) => this.toCoursePeriod(period)) ?? [],
    } as CourseAcademicYear;
  }

  // Sort courses: ribbon courses first by code, then the others by code
  public sortCourses(courses: Course[]): Course[] {
    return [
      ...courses.filter((c) => this.hasRibbonPeriods(c) && !this.isCustomCourse(c)).sort(this.sortCourse),
      ...courses.filter((c) => !this.hasRibbonPeriods(c) && !this.isCustomCourse(c)).sort(this.sortCourse),
      ...courses.filter((c) => this.isCustomCourse(c)).sort(this.sortCourse),
    ];
  }

  private sortCourse(course1: Course, course2: Course): number {
    const code1 = course1.code ?? course1.name ?? course1.customCourseName ?? "";
    const code2 = course2.code ?? course2.name ?? course2.customCourseName ?? "";
    return code1.localeCompare(code2);
  }

  private toCoursePeriod(period: Period): CoursePeriod {
    return {
      current: period.actual,
      periodNr: period.periodNr,
      periodDescription: period.periodDescription,
      ribbonPeriods: [],
      timeslots: [],
      offered: true,
    } as CoursePeriod;
  }

  isMovableToPlanLater(course: Course, plan?: PlanDetails): boolean {
    return !this.isCustomCourse(course) && !course.isInPlanLater && !this.courseMustStayInPlan(course, plan);
  }
}
