import { inject, Injectable } from "@angular/core";
import { ApiCollegeJaar } from "@core/api/model/api-collegejaren";
import {
  ApiCurriculum,
  ApiCurriculumCursus,
  ApiCurriculumExamenonderdeel,
  ApiCurriculumExamenProgramma,
} from "@core/api/model/api-curriculum";
import { ApiCursusDetail } from "@core/api/model/api-cursus-detail";
import { ApiDraft, ApiDraftResponse } from "@core/api/model/api-draft";
import { ApiEducation } from "@core/api/model/api-education";
import { ApiExamenProgramma } from "@core/api/model/api-examen-programma";
import { ApiExamenType } from "@core/api/model/api-examen-type";
import { ApiMinorInschrijving } from "@core/api/model/api-minor";
import {
  ApiPlanDetail,
  ApiPlanItem,
  ApiPlanProfilering,
  ApiPlanResponse,
  ApiStatusResponse,
} from "@core/api/model/api-plan";
import { ApiPlanHistory, ApiPlanHistoryDetails } from "@core/api/model/api-plan-history";
import { ApiPlanLater } from "@core/api/model/api-plan-later";
import { AcademicYear } from "@core/domain/academic-year";
import { CourseDetail } from "@core/domain/course-detail";
import { Curriculum, CurriculumCourse, CurriculumExamComponent, CurriculumStudyProgram } from "@core/domain/curriculum";
import { Draft, DraftResponse } from "@core/domain/draft";
import { Education } from "@core/domain/education";
import { ExamType } from "@core/domain/examtype";
import { ApiHelper } from "@core/domain/helpers/api-helper";
import { MinorRegistration } from "@core/domain/minor";
import { Plan } from "@core/domain/plan";
import { PlanDetails, PlanResponse, StatusResponse } from "@core/domain/plan-details";
import { PlanHistory, PlanHistoryDetails } from "@core/domain/plan-history";
import { PlanLater } from "@core/domain/plan-later";
import { PlanProfile } from "@core/domain/plan-profile";
import { StudyProgram } from "@core/domain/study-program";
import { CourseMappingService } from "@core/mapping/course-mapping.service";
import { PlanHistoryMappingService } from "@core/mapping/plan-history-mapping.service";
import { PlanLaterMappingService } from "@core/mapping/plan-later-mapping.service";
import { PlanMappingService } from "@core/mapping/plan-mapping.service";

@Injectable({
  providedIn: "root",
})
export class ApiMappingService {
  private courseMapper = inject(CourseMappingService);
  private planMapper = inject(PlanMappingService);
  private planLaterMapper = inject(PlanLaterMappingService);
  private planHistoryMapper = inject(PlanHistoryMappingService);

  toPlan(item: ApiPlanItem): Plan {
    return {
      id: item.spla_id,
      name: item.planning_naam,
      degreeProgram: item.opleiding,
      yearNr: item.start_jaar,
      yearCount: item.aantal_jaar,
      draft: ApiHelper.caciBooleanToBoolean(item.concept_planning),
    } as Plan;
  }

  toPlanDetail(apiPlan: ApiPlanDetail, forValidation = false): PlanDetails {
    return this.planMapper.mapPlan(apiPlan, forValidation);
  }

  toPlanProfile(apiPlanProfil: ApiPlanProfilering): PlanProfile {
    return this.planMapper.mapPlanProfile(apiPlanProfil);
  }

  toCourseDetail(apiCursusDetail: ApiCursusDetail): CourseDetail {
    return this.courseMapper.mapCourseDetail(apiCursusDetail);
  }

  toCurriculum(apiCurriculum: ApiCurriculum): Curriculum {
    return {
      id: apiCurriculum.spla_id,
      studentNr: apiCurriculum.studentnummer,
      studentName: apiCurriculum.studentnaam,
      staffMember: apiCurriculum.medewerker,
      planName: apiCurriculum.planning_naam,
      draft: ApiHelper.caciBooleanToBoolean(apiCurriculum.concept_planning),
      startingYear: apiCurriculum.start_jaar,
      yearCount: apiCurriculum.aantal_jaar,
      submitDate: apiCurriculum.datum_indienen,
      decisionDate: apiCurriculum.datum_besluit,
      adviceDate: apiCurriculum.datum_advies,
      lastSaved: apiCurriculum.laatst_opgeslagen,
      adviceExplanation: apiCurriculum.toelichting_advies,
      decisionExplanation: apiCurriculum.toelichting_besluit,
      proposalStatusDescription: apiCurriculum.status,
      proposalReviewMomentDescription: apiCurriculum.beoordelingsmoment,
      studyPrograms:
        apiCurriculum.examenprogrammas?.map((examenprogramma: ApiCurriculumExamenProgramma) =>
          this.toCurriculumStudyProgram(examenprogramma),
        ) ?? [],
    } as Curriculum;
  }

  private toCurriculumStudyProgram(examenprogramma: ApiCurriculumExamenProgramma): CurriculumStudyProgram {
    return {
      degreeProgram: examenprogramma.opleiding,
      code: examenprogramma.examenprogramma,
      name: examenprogramma.naam_examenprogramma,
      examType: examenprogramma.examentype,
      minimumPoints: examenprogramma.minimum_punten,
      profileName: examenprogramma.profil_naam,
      examComponents: examenprogramma.examenonderdelen.map((examenonderdeel: ApiCurriculumExamenonderdeel) =>
        this.toCurriculumExamenonderdeel(examenonderdeel),
      ),
    } as CurriculumStudyProgram;
  }

  private toCurriculumExamenonderdeel(examenonderdeel: ApiCurriculumExamenonderdeel): CurriculumExamComponent {
    return {
      name: examenonderdeel.naam,
      order: examenonderdeel.volgorde,
      type: examenonderdeel.type,
      typeDescription: examenonderdeel.type_oms,
      level: examenonderdeel.niveau,
      studyProgram: examenonderdeel.examenprogramma,
      explanation: examenonderdeel.toelichting,
      code: examenonderdeel.code,
      subcomponentsRequirement: examenonderdeel.eis_onderliggende_onderdelen,
      minimumPoints: examenonderdeel.minimum_punten,
      pointsAchieved: examenonderdeel.behaalde_punten,
      planningState: examenonderdeel.status_planning,
      conditionsMet: ApiHelper.caciBooleanToBoolean(examenonderdeel.voldaan),
      chosen: ApiHelper.caciBooleanToBoolean(examenonderdeel.gekozen_of_tak),
      typeTemporary: examenonderdeel.type_array_TIJDELIJK,
      courses: examenonderdeel.cursussen.map((cursus) => this.toCurriculumCursus(cursus)),
      pointsPlanned: examenonderdeel.geplande_punten,
    } as CurriculumExamComponent;
  }

  private toCurriculumCursus(course: ApiCurriculumCursus): CurriculumCourse {
    return this.courseMapper.mapCurriculumCourse(course);
  }

  toPlanResponse(response: ApiPlanResponse): PlanResponse {
    if (response.statusmeldingen?.length) {
      return this.toStatusResponse(response as ApiStatusResponse);
    }

    return this.toPlanDetail(response as ApiPlanDetail);
  }

  toStatusResponse(response: ApiStatusResponse): StatusResponse {
    return {
      statusMessages: response.statusmeldingen.map((msg) => ApiHelper.toStatusMessage(msg)),
    } as StatusResponse;
  }

  toPlanLater(apiPlanLater: ApiPlanLater): PlanLater {
    return this.planLaterMapper.mapPlanLater(apiPlanLater);
  }

  toPlanHistory(history: ApiPlanHistory): PlanHistory {
    return this.planHistoryMapper.mapPlanHistory(history);
  }

  toPlanHistoryDetails(historyDetails: ApiPlanHistoryDetails): PlanHistoryDetails {
    return this.planHistoryMapper.mapPlanHistoryDetails(historyDetails);
  }

  toExamType(apiExamType: ApiExamenType): ExamType {
    return {
      type: apiExamType.examentype,
      description: apiExamType.omschrijving_nls,
    } as ExamType;
  }

  toEducation(apiEducation: ApiEducation): Education {
    return {
      examType: apiEducation.examentype,
      name: apiEducation.naam,
      education: apiEducation.opleiding,
    } as Education;
  }

  toStudyProgram(apiStudyProgram: ApiExamenProgramma): StudyProgram {
    return {
      id: apiStudyProgram.exam_id,
      code: apiStudyProgram.examenprogramma,
      showSecondStudyProgram: ApiHelper.caciBooleanToBoolean(apiStudyProgram.tweede_examenprogramma_tonen),
      name: apiStudyProgram.naam,
      isRegisteredByThisStudent: ApiHelper.caciBooleanToBoolean(apiStudyProgram.examenprogramma_student),
    } as StudyProgram;
  }

  toApiDraft(draft: Draft): ApiDraft {
    return {
      opleiding: draft.education,
      examenprogramma: draft.studyProgramCode,
      planning_naam: draft.name,
      start_jaar: draft.startingYear,
      tweede_examenprogramma: draft.secondStudyProgramCode,
      examen_type: draft.examType,
    } as ApiDraft;
  }

  toDraftResponse(response: ApiDraftResponse): DraftResponse {
    return {
      id: response.spla_id,
      statusMessages: response.statusmeldingen.map((msg) => ApiHelper.toStatusMessage(msg)),
    } as DraftResponse;
  }

  toAcademicYear(response: ApiCollegeJaar): AcademicYear {
    return {
      year: response.collegejaar,
      description: response.collegejaar_oms,
    } as AcademicYear;
  }

  toMinorInschrijving(item: ApiMinorInschrijving): MinorRegistration {
    return {
      minor: item.minor,
      academicYear: item.collegejaar,
      startingBlock: item.aanvangsblok,
      os_uitschr: item.os_uitschr,
    } as MinorRegistration;
  }
}
