export class Constants {
  static readonly ANIMATION_DURATION = 500;
  static readonly TOAST_DURATION = 4000;

  static readonly LANGUAGE_KEY = "lang";
  static readonly LANGUAGE_NL = "nl";
  static readonly LANGUAGE_EN = "en";
  static readonly LANGUAGE_DEFAULT = Constants.LANGUAGE_NL;
  static readonly LANGUAGE_QUERY_PARAMETER = "taal";

  static readonly BUTTON_ACTION_OK = "ok";
  static readonly BUTTON_ACTION_CANCEL = "cancel";
  static readonly BUTTON_ACTION_PLANLATER = "planlater";

  static readonly SEPARATOR_COMMA = ",";

  static readonly REMARK_MAX_LENGTH = 500;

  static readonly SEARCH_DEBOUNCE_TIME_MS = 500;

  static readonly VIEW_STARTED_BY_PLAN = "PLAN";
  static readonly VIEW_STARTED_BY_PLANLATER = "PLAN_LATER";
  static readonly VIEW_STARTED_BY_CATALOG = "CATALOG";
  static readonly VIEW_STARTED_BY_CURRICULUM = "CURRICULUM";

  static readonly MAX_LENGTH_COURSE_NAME = 40;
  static readonly MAX_LENGTH_PLAN_NAME = 35;

  static readonly MIN_STUDY_POINTS = 0;
  static readonly MAX_STUDY_POINTS = 15;
}
