import { Component, inject, Input, OnInit } from "@angular/core";
import { PlanHistory, PlanHistoryDetails, PlanHistoryItem } from "@core/domain/plan-history";
import { PlanHistoryService } from "@core/services/plan-history.service";
import { TranslateService } from "@ngx-translate/core";
import { HistoryDetailsComponent } from "../history-details/history-details.component";
import { HistoryCoursesComponent } from "../history-courses/history-courses.component";
import { IonButton, IonContent, IonIcon, IonItem, IonList, IonPopover } from "@ionic/angular/standalone";

@Component({
  selector: "pa-history-info",
  templateUrl: "./history-info.component.html",
  styleUrls: ["./history-info.component.scss"],
  standalone: true,
  imports: [
    HistoryDetailsComponent,
    HistoryCoursesComponent,
    IonButton,
    IonIcon,
    IonPopover,
    IonContent,
    IonList,
    IonItem,
  ],
})
export class HistoryInfoComponent implements OnInit {
  @Input() history!: PlanHistory;
  selectedItem: PlanHistoryItem | undefined;
  historyDetails: PlanHistoryDetails | undefined;
  historyItemsOpen = false;
  private planHistoryService = inject(PlanHistoryService);
  private translate = inject(TranslateService);

  ngOnInit(): void {
    this.selectedItem = undefined;

    // If history has items, select the first one by default
    if (this.history?.items?.length) {
      this.selectItem(this.history.items[0]);
    }
  }

  selectItem(item: PlanHistoryItem): void {
    if (item) {
      this.selectedItem = item;
      this.fetchPlanHistoryDetails(this.selectedItem);
    }
    this.historyItemsOpen = false;
  }

  getHistoryItemName(item?: PlanHistoryItem): string {
    if (item?.statusDescription?.length) {
      return item.decisionDate?.length ? `${item.statusDescription} (${item.decisionDate})` : item.statusDescription;
    }

    return this.translate.instant("schedule.history.selectHistoryItem");
  }

  private fetchPlanHistoryDetails(item: PlanHistoryItem) {
    this.planHistoryService.fetchPlanHistoryDetails(item.splaId, item.hsplId, item.studentNr).subscribe((details) => {
      this.historyDetails = details;
    });
  }
}
