import { Component, inject, Input } from "@angular/core";
import { CourseHelper } from "@core/domain/helpers/course-helper";
import { PlanHistoryDetailCourse } from "@core/domain/plan-history";
import { NgStyle } from "@angular/common";
import { StudyPointsComponent } from "@shared/components/study-points/study-points.component";
import { IonItem } from "@ionic/angular/standalone";

@Component({
  selector: "pa-history-course-item",
  templateUrl: "./history-course-item.component.html",
  styleUrls: ["./history-course-item.component.scss"],
  standalone: true,
  imports: [NgStyle, StudyPointsComponent, IonItem],
})
export class HistoryCourseItemComponent {
  @Input() course!: PlanHistoryDetailCourse;
  protected courseHelper = inject(CourseHelper);
}
