import { Component, inject, Input } from "@angular/core";
import { Router } from "@angular/router";
import { Constants } from "@core/constants";
import { CourseDetail } from "@core/domain/course-detail";
import { CourseHelper } from "@core/domain/helpers/course-helper";
import { CatalogSearchInfo } from "@feature/catalog/pages/catalog-page.component";
import {
  IonButton,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonTitle,
  IonToolbar,
  ModalController,
} from "@ionic/angular/standalone";
import { TranslatePipe } from "@ngx-translate/core";
import { PlanActionService } from "@shared/services/plan-action.service";
import { PlanStateService } from "@shared/services/plan-state.service";
import { CourseData, SelectedPeriod } from "@shared/types/course-data";
import { CourseDetailComponent } from "../../components/course-detail/course-detail.component";

@Component({
  selector: "pa-course-info-modal",
  templateUrl: "./course-info-modal.component.html",
  styleUrls: ["./course-info-modal.component.scss"],
  standalone: true,
  imports: [
    CourseDetailComponent,
    TranslatePipe,
    IonHeader,
    IonToolbar,
    IonButton,
    IonTitle,
    IonIcon,
    IonContent,
    IonFooter,
  ],
})
export class CourseInfoModalComponent {
  @Input() courseData!: CourseData;
  @Input() courseDetail!: CourseDetail;
  @Input() isScheduleSelectable = false;
  @Input() viewStartedBy!: string;
  protected readonly Constants = Constants;
  private planStateService = inject(PlanStateService);
  private planActionService = inject(PlanActionService);
  private modalCtrl = inject(ModalController);
  private courseHelper = inject(CourseHelper);
  private router = inject(Router);

  showRemove(): boolean {
    const plan = this.planStateService.currentSelectedPlan();
    return this.courseHelper.isRemoveAllowed(this.courseData, plan);
  }

  removeFromPlanning(): void {
    this.dismiss().then(() => this.planActionService.removeCourseFromPlan(this.courseData));
  }

  dismiss(): Promise<boolean> {
    return this.modalCtrl.dismiss(null, "cancel");
  }

  selectedPeriod(selectedPeriod: SelectedPeriod): void {
    this.planActionService.moveCourseFromCourseInfo(this.courseData, selectedPeriod);
    this.modalCtrl.dismiss(null, Constants.BUTTON_ACTION_OK);
  }

  openCatalog(event: CatalogSearchInfo): void {
    this.dismiss();
    this.router.navigate(["/catalog/minors"], {
      queryParams: { minorCode: event.code, minorName: event.name, minorDescription: event.description },
    });
  }
}
