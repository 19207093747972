import { HttpParams } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { MainPage } from "@app/shared/types/pages";
import { ApiPlanLater, ApiPlanLaterResponse } from "@core/api/model/api-plan-later";
import { PlanLater } from "@core/domain/plan-later";
import { ApiMappingService } from "@core/mapping/api-mapping.service";
import { GenericHttpService } from "@core/services/generic-http.service";
import { map, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PlanLaterService extends GenericHttpService {
  private mappingService = inject(ApiMappingService);

  private apiPlanLater: ApiPlanLater | undefined;

  fetchPlanLater(id: number): Observable<PlanLater | undefined> {
    const params = new HttpParams().set("spla_id", id.toString());

    return this.httpGet<ApiPlanLaterResponse>("plan_later", params, undefined, MainPage.PLAN).pipe(
      map((response) => {
        if (!response) {
          return undefined;
        }

        this.apiPlanLater = response as ApiPlanLater;
        return this.mappingService.toPlanLater(this.apiPlanLater);
      }),
    );
  }

  fetchCoursesForPeriod(planId: number, year: number, period: number): Observable<PlanLater> {
    const params = {} as HttpParams;
    const body = {
      ...this.apiPlanLater,
      studiejaar: year,
      periode_nr: period,
    };

    return this.httpPost<ApiPlanLater>(`plan/${planId}/cursussen_per_periode`, params, JSON.stringify(body)).pipe(
      map((courseForPeriod) => {
        // courseForPeriod is of same type as PlanLater
        return this.mappingService.toPlanLater(courseForPeriod);
      }),
    );
  }
}
