import { Injectable } from "@angular/core";
import { StatusMessage } from "@core/domain/status-message";

@Injectable({
  providedIn: "root",
})
export class StatusMessageHelper {
  constructor() {}

  isGenericStatusMessage(statusMessage: StatusMessage): boolean {
    return statusMessage.year === undefined;
  }

  isPeriodStatusMessage(message: StatusMessage, year: number, period: number): boolean {
    return message.year === year && message.period === period && message.courseCode === undefined;
  }

  isCourseStatusMessage(message: StatusMessage, year: number, period: number, courseCode?: string): boolean {
    if (!courseCode) {
      // custom courses never have a status message, so if there is no course code return false
      return false;
    }
    return message.year === year && message.period === period && message.courseCode === courseCode;
  }
}
