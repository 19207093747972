import { HttpParams } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { MainPage } from "@app/shared/types/pages";
import { ApiPlanDetail, ApiPlanItem, ApiPlannen, ApiPlannenResponse } from "@core/api/model/api-plan";
import { Plan } from "@core/domain/plan";
import { PlanDetails, PlanResponse, StatusResponse } from "@core/domain/plan-details";
import { PlanLater } from "@core/domain/plan-later";
import { ApiMappingService } from "@core/mapping/api-mapping.service";
import { PlanRequestMappingService } from "@core/mapping/plan-request-mapping.service";
import { GenericHttpService } from "@core/services/generic-http.service";
import { PlanLaterService } from "@core/services/plan-later.service";
import { LoadingService } from "@shared/services/loading-service";
import { PlanStateService } from "@shared/services/plan-state.service";
import { map, Observable, of, switchMap, tap } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PlanService extends GenericHttpService {
  private mappingService = inject(ApiMappingService);
  private planLaterService = inject(PlanLaterService);
  private planStateService = inject(PlanStateService);
  private planRequestMapper = inject(PlanRequestMappingService);
  private loadingService = inject(LoadingService);

  fetchPlans(): Observable<Plan[]> {
    return this.httpGet<ApiPlannenResponse>("plannen", undefined, undefined, MainPage.PLAN).pipe(
      map((response) => {
        if (!response) {
          // Status messages are already processed...
          return {
            items: [],
          } as unknown as ApiPlannen;
        }

        return response as ApiPlannen;
      }),
      map((plans) => {
        return plans.items.map((item) => this.mappingService.toPlan(item as ApiPlanItem));
      }),
    );
  }

  fetchPlan(planId: number): Observable<PlanDetails | undefined> {
    const params = new HttpParams().set("spla_id", planId.toString());

    return this.httpGet<ApiPlanDetail | undefined>("plan", params, undefined, MainPage.PLAN).pipe(
      map((plan) => {
        return plan ? this.mappingService.toPlanDetail(plan) : undefined;
      }),
    );
  }

  validatePlan(planToValidate: PlanDetails, planLater: PlanLater): Observable<PlanDetails> {
    const validatePlanURL = `plan/${planToValidate.id}/valideer`;
    const apiValideerPlan = this.planRequestMapper.buildApiPlanRequest(planToValidate, planLater!);

    if (planToValidate.validationAllowed) {
      return this.httpPost<ApiPlanDetail>(validatePlanURL, undefined, JSON.stringify(apiValideerPlan)).pipe(
        map((plan) => {
          // Validate successful, so plan was implicitly saved
          this.planStateService.markPlanAsModified(false);
          // Update the plan in the state
          return this.mappingService.toPlanDetail(
            plan,
            true, // forValidation
          );
        }),
      );
    }
    // if validation is not allowed, return the planToValidate unchanged
    return of(planToValidate);
  }

  savePlan(planToSave: PlanDetails, planLater: PlanLater): Observable<PlanDetails | undefined> {
    const savePlanURL = `plan/${planToSave.id}`;
    const apiPlanRequest = this.planRequestMapper.buildApiPlanRequest(planToSave, planLater!);

    return this.httpPut<ApiPlanDetail>(savePlanURL, undefined, JSON.stringify(apiPlanRequest)).pipe(
      map((response) => this.mappingService.toPlanDetail(response)),
      tap((planDetails) => this.statusMessageService.setStatusMessages(planDetails?.statusMessages)),
      switchMap((planDetails) => {
        if (planDetails.id === undefined) {
          // Save failed, so return the original planToSave
          return of(undefined);
        }

        // After successfully saving the plan, make sure the corresponding planLater is also fetched
        return this.getOrFetchPlanLater(planDetails.id, true).pipe(
          map(() => {
            // Save successful, so update the plan in the state
            this.planStateService.markPlanAsModified(false);
            this.planStateService.setPlanDetails(planDetails.id, planDetails);
            return planDetails;
          }),
        );
      }),
    );
  }

  getOrFetchPlanDetails(planId: number, forceLoad = false): Observable<PlanDetails | undefined> {
    const plan = this.planStateService.getPlanDetails(planId);
    if (plan && !forceLoad) {
      // Return the cached model as long as it has not been cleared. But first fetch the planLater.
      return this.getOrFetchPlanLater(planId).pipe(
        map(() => plan),
        tap(() => this.planStateService.setPlanDetails(planId, plan)),
        tap(() => this.planStateService.markPlanAsModified(false)),
      );
    }

    // Or else fetch via api
    return this.loadingService.present("label.loadingPlan").pipe(
      switchMap(() => this.fetchPlan(planId)),
      switchMap((planDetails: PlanDetails | undefined) => {
        // When fetching the plan, also fetch the corresponding planLater
        return planDetails
          ? this.getOrFetchPlanLater(planId).pipe(
              map(() => planDetails),
              tap(() => this.planStateService.setPlanDetails(planId, planDetails)),
              tap(() => this.planStateService.markPlanAsModified(false)),
            )
          : of(undefined);
      }),
      tap(() => this.loadingService.dismiss()),
      tap((planDetails) => {
        planDetails && this.planStateService.setPlanDetails(planId, planDetails);
      }),
    );
  }

  getOrFetchPlanLater(planId: number, forceReload = true): Observable<PlanLater | undefined> {
    const planLater = this.planStateService.planLater();
    if (!forceReload && planLater && planLater.id === planId) {
      // No need to fetch again...
      return of(planLater);
    }

    return this.planLaterService.fetchPlanLater(planId).pipe(
      tap((planLaterResult) => {
        if (planLaterResult) {
          this.planStateService.planLater.set({ ...planLaterResult });
        }
      }),
    );
  }

  updatePlanFromResponse(planResponse: PlanResponse): Observable<PlanDetails | undefined> {
    if ((planResponse as StatusResponse)!.statusMessages!.length) {
      this.statusMessageService.setStatusMessages((planResponse as StatusResponse).statusMessages);
      return of(undefined);
    }

    // No status messages, then the response is the updated plan
    const plan = planResponse as PlanDetails;
    if (plan!.id) {
      // console.log("updateSelectedPlan(): plan updated:", plan);
      this.planStateService.setPlanDetails(plan.id, plan);
      this.planStateService.markPlanAsModified(false);

      // Still call getOrFetchPlanDetails to make sure planLater is fetched if needed
      return this.getOrFetchPlanDetails(plan.id);
    }

    return of(undefined);
  }

  markPlanAsResubmittable(planId: number): Observable<PlanDetails | undefined> {
    const urlForMarkPlanAsResubmittable = `/plan/${planId}/opnieuw_indienen`;

    return this.httpPut<ApiPlanDetail>(urlForMarkPlanAsResubmittable).pipe(
      map((response) => this.mappingService.toPlanDetail(response)),
      tap((planDetails) => this.statusMessageService.setStatusMessages(planDetails?.statusMessages)),
      switchMap((planDetails) => {
        if (planDetails.id === undefined) {
          // Save failed, so return the original planToSave
          return of(undefined);
        }

        // After successfully saving the plan, make sure the corresponding planLater is also fetched
        return this.getOrFetchPlanLater(planDetails.id, true).pipe(
          map(() => {
            // Save successful, so update the plan in the state
            this.planStateService.markPlanAsModified(false);
            this.planStateService.setPlanDetails(planDetails.id, planDetails);
            return planDetails;
          }),
        );
      }),
    );
  }

  public getOrFetchPlans(forceReload = false): Observable<Plan[] | undefined> {
    const plans = this.planStateService.plans();
    if (plans?.length && !forceReload) {
      return of(plans);
    }

    return this.fetchPlans().pipe(
      tap((plansResult) => {
        this.planStateService.plans.set(plansResult);
      }),
    );
  }
}
