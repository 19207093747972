@if (courseDetail) {
  <div class="pa-course-detail">
    @if (courseDetail.content) {
      <div class="pa-course-detail-item" tabindex="0">
        <p class="pa-course-detail-content" [innerHTML]="courseDetail.content"></p>
      </div>
    }

    <div class="pa-course-detail-item" tabindex="0" role="list">
      <dt class="pa-course-detail-label" role="listitem">{{ 'courseDetail.studyPoints' | translate }}</dt>
      <dd class="pa-course-detail-value" role="definition">{{ courseDetail.studyPoints }}</dd>
    </div>

    <div class="pa-course-detail-item" tabindex="0" role="list">
      <dt class="pa-course-detail-label" role="listitem">{{ 'courseDetail.category' | translate }}</dt>
      <dd class="pa-course-detail-value" role="definition">{{ courseDetail.categoryDescription }}</dd>
    </div>

    <div class="pa-course-detail-item" tabindex="0" role="list">
      <dt class="pa-course-detail-label" role="listitem">{{ 'courseDetail.language' | translate }}</dt>
      <dd class="pa-course-detail-value" role="definition">{{ courseDetail.mainLanguages }}</dd>
    </div>

    <div class="pa-course-detail-item" tabindex="0" role="list">
      <dt class="pa-course-detail-label" role="listitem">{{ 'courseDetail.docent' | translate }}</dt>
      <dd class="pa-course-detail-value" role="definition">
        <ul class="pa-course-detail-list">
          @for (tutor of courseDetailTutors; track tutor) {
            <li>{{ tutor }}</li>
          }
        </ul>
      </dd>
    </div>

    <div class="pa-course-detail-item" tabindex="0" role="list">
      <dt class="pa-course-detail-label" role="listitem">{{ 'courseDetail.offeredBy' | translate }}</dt>
      <dd class="pa-course-detail-value" role="definition">{{ courseDetail.facultyName }}</dd>
    </div>

    <div class="pa-course-detail-item" tabindex="0" role="list">
      <dt class="pa-course-detail-label" role="listitem">{{ 'courseDetail.partOf' | translate }}</dt>
      <dd class="pa-course-detail-value" role="definition">
        <ul class="pa-course-detail-list">
          @for (minor of courseDetail.partOfMinor; track minor.minor) {
            <li tabindex="0">
              @if (hasMinorsInCatalog) {
                <a class="pa-course-detail-link" (click)="searchMinor(minor)">
                  <b>
                    <u>{{ minor.studyProgram }} {{ minor.minorName }}</u>
                  </b>
                </a>
              } @else {
                {{ minor.studyProgram }} {{ minor.minorName }}
              }
            </li>
          }
        </ul>
      </dd>
    </div>

    <div class="pa-course-detail-item" tabindex="0" role="list">
      <dt class="pa-course-detail-label" role="listitem">
        {{ 'courseDetail.prerequisites' | translate }}
      </dt>
      <dd class="pa-course-detail-value" role="definition">
        @if (courseDetail.prerequirement) {
          <span id="preRequirementID" [innerHTML]="courseDetail.prerequirement"></span>
          <span [innerHTML]="courseDetail.prerequirementNotFulfilled"></span>

          @if (courseDetail.insiderKnowledge) {
            <small>{{ 'courseDetail.insiderKnowledge' | translate }}</small>
            <ul>
              <li><span [innerHTML]="courseDetail.insiderKnowledge"></span></li>
            </ul>
          }

          @if (courseDetail.gainInsiderKnowledge) {
            <small>{{ 'courseDetail.gainInsiderKnowledge' | translate }}</small>
            <ul>
              <li><span [innerHTML]="courseDetail.gainInsiderKnowledge"></span></li>
            </ul>
          }

          @if (courseDetail.selfstudy) {
            <small>{{ 'courseDetail.selfStudy' | translate }}</small>
            <ul>
              <li><span [innerHTML]="courseDetail.selfstudy"></span></li>
            </ul>
          }
        }
      </dd>
    </div>

    <div class="pa-course-detail-item" tabindex="0" role="list">
      <dt class="pa-course-detail-label" role="listitem">
        @if (courseDetail.isFlexCourse) {
          {{ 'schedule.textFlexPlannen' | translate }}
        } @else {
          {{ 'courseDetail.offeredIn' | translate }}
        }
      </dt>
      <dd class="pa-course-detail-value" role="definition">
        @if (courseDetail.isFlexCourse) {
          <div>{{ 'schedule.explanationFlexPlan' | translate }}</div>
          <ng-template [ngxPermissionsExcept]="Role.EMPLOYEE_VIEWS_STUDENT_PLAN">
            <ion-button (click)="onPlanNow($event)" class="course-action-button" color="secondary">
              {{ 'label.planNow' | translate }}
            </ion-button>
          </ng-template>
        } @else {
          @if (isScheduleSelectable) {
            <pa-plan-element
              [courseDetail]="courseDetail"
              isScheduleSelectable
              (selectedPeriod)="onSelectedPeriod($event)"
            />
          } @else {
            <pa-plan-element [courseDetail]="courseDetail" />
          }
        }
      </dd>
    </div>
  </div>
}
