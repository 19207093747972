import { Component, inject, Input } from "@angular/core";
import { Role } from "@app/core/domain/user";
import { Constants } from "@core/constants";
import { CourseDetail } from "@core/domain/course-detail";
import { IonButton, IonFooter, IonIcon, IonTitle, IonToolbar, ModalController } from "@ionic/angular/standalone";
import { TranslatePipe } from "@ngx-translate/core";
import { CourseData, SelectedPeriod } from "@shared/types/course-data";
import { NgxPermissionsModule } from "ngx-permissions";
import { PlanElementComponent } from "../../components/course-detail/plan-element/plan-element.component";

/**
 * "Plan Now" modal, but also used for "Move" from a course on the plan grid.
 */
@Component({
  selector: "pa-schedule-course",
  templateUrl: "./schedule-course-modal.component.html",
  styleUrls: ["./schedule-course-modal.component.scss"],
  standalone: true,
  imports: [
    PlanElementComponent,
    NgxPermissionsModule,
    TranslatePipe,
    IonToolbar,
    IonButton,
    IonTitle,
    IonIcon,
    IonFooter,
  ],
})
export class ScheduleCourseModalComponent {
  @Input() courseData!: CourseData;
  @Input() courseDetail!: CourseDetail;
  @Input() showMoveToPlanLaterButton = false;
  protected readonly Role = Role;
  private modalCtrl = inject(ModalController);

  dismiss(): void {
    this.modalCtrl.dismiss(null, Constants.BUTTON_ACTION_CANCEL);
  }

  selectedPeriod(selectedPeriod: SelectedPeriod): void {
    this.modalCtrl.dismiss(selectedPeriod, Constants.BUTTON_ACTION_OK);
  }

  moveToPlanLater(): void {
    this.modalCtrl.dismiss(null, Constants.BUTTON_ACTION_PLANLATER);
  }
}
