import { Component, inject, Input } from "@angular/core";
import { Constants } from "@core/constants";
import { PlanHistory } from "@core/domain/plan-history";
import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonTitle,
  IonToolbar,
  ModalController,
} from "@ionic/angular/standalone";
import { TranslatePipe } from "@ngx-translate/core";
import { HistoryInfoComponent } from "./history-item/history-info.component";

@Component({
  selector: "pa-show-history-modal",
  templateUrl: "./show-history-modal.component.html",
  styleUrls: ["./show-history-modal.component.scss"],
  standalone: true,
  imports: [HistoryInfoComponent, TranslatePipe, IonHeader, IonToolbar, IonButton, IonTitle, IonIcon, IonContent],
})
export class ShowHistoryModalComponent {
  @Input() title!: string;
  @Input() history: PlanHistory | undefined;
  private modalCtrl = inject(ModalController);

  dismiss(): Promise<boolean> {
    return this.modalCtrl.dismiss(null, Constants.BUTTON_ACTION_OK);
  }
}
