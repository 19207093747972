<span>{{ 'choiceMinorOrElectives.selectionText' | translate }}</span>

@for (group of courseList(); track $index) {
  <pa-add-course-list-header [title]="group.title" [subTitle]="group.subTitle" />

  @for (item of group.items; track $index) {
    <pa-add-course-list-item
      [selected]="item.selected"
      [selectable]="item.selectable && !pointsReached()"
      [disabled]="item.disabled"
      (selectCourse)="onSelectCourse(item, $event)"
    >
      <pa-course-item [courseData]="item.courseData" showCategory hideShowInfo hideTimeslots />
    </pa-add-course-list-item>
  }
}
