<ng-container>
  <div class="history-row">
    <b tabindex="0">
      {{ 'schedule.history.yourChoicesMinorElectives' | translate }} {{ historyDetails.profileName }}:
    </b>
    @if (historyDetails.individualArrangements.length) {
      <pa-add-course-list-header [title]="'schedule.history.coursesIndividualArrangements' | translate" tabindex="0" />
      @for (course of historyDetails.individualArrangements; track $index) {
        <pa-history-course-item [course]="course" tabindex="0" />
      }
    }

    @if (historyDetails.courses.length) {
      <pa-add-course-list-header [title]="'schedule.history.courses' | translate" tabindex="0" />
      @for (course of historyDetails.courses; track $index) {
        <pa-history-course-item [course]="course" tabindex="0" />
      }
    }
  </div>
</ng-container>
