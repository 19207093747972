import { Component, Input } from "@angular/core";

@Component({
  selector: "pa-timeslot",
  templateUrl: "./timeslot.component.html",
  styleUrls: ["./timeslot.component.scss"],
  standalone: true,
})
export class TimeslotComponent {
  @Input() timeslot!: string;
}
